import React from 'react'
import { Container, Typography, Divider } from '@material-ui/core';
import SEO from '../../components/seo'
import Layout from '../../components/layout';
import { graphql, StaticQuery } from 'gatsby';

const instantPush = () => {
  return (
    <StaticQuery
          query={graphql`
          query InstantPushPageQuery {
            site {
              siteMetadata {
                title
                env
                imagesPrefix
              }
            }
          }
          `}
          render={data => (
            <Layout location="">
            <SEO title='Instant Push' description='Blix instant push'/>
            <Container fixed style={{paddingBottom: '30px', paddingTop: '70px'}}>
                <img style={{width: '100%'}} src={`${data.site.siteMetadata.imagesPrefix}/Blix_Features_Instant_Push_V3.png`} alt="Blix_Features_Instant_Push_V3"/>
                <Typography color="textPrimary" variant="h3" align="center" style={{marginTop: '30px', marginBottom: '30px'}}>Instant Push</Typography>
                <Divider/>
                <Typography color="textSecondary" style={{marginTop: '30px', fontSize: '18px'}}>Blix intelligently pushes your emails so they arrive into your device at instantaneous speeds saving you valuable time. With Blix’s simplicity in configuring your accounts, receiving your emails has never been faster or more intuitive. We believe we have come up with the best possible solution to this challenge both in terms of user experience and data safety on all platfroms including on mobile and desktop.</Typography>
                <Typography color="textPrimary" variant="h5" style={{marginTop: '30px'}}>Instant Push Architecture</Typography>
                <Typography color="textSecondary" style={{marginTop: '30px', fontSize: '18px'}}>Blix uses state-of-the-art technologies to keep the security and privacy of our users a top priority. We strongly believe that users own their information and we never sell any data of our users and emails are never stored at any time. Our unique technological architecture allows us to deliver a safer solution which is providing industry leading email and calendar services for you and your organization.</Typography>
                <Typography color="textSecondary" style={{marginTop: '30px', fontSize: '18px'}}>We invest a lot of effort in trying to come up with the best email solution while maintaining a service with great user experience, with a special emphasis on privacy, reliability, efficiency, and security. These are among the main reasons why professionals prefer Blix over other solutions.</Typography>
                <Typography color="textSecondary" style={{marginTop: '30px', fontSize: '18px'}}>Blix for Android, iOS, Windows, and Linux connect directly to your email provider. We believe that this is the right approach for keeping your email data secure, as emails are transmitted directly between Blix and your email provider. Designing Blix to work this way was achieved using a unique architectural approach, making it significantly more complex to implement. We are convinced that this is the right way to provide an excellent service while protecting your privacy.</Typography>
                <Typography color="textSecondary" style={{marginTop: '30px', fontSize: '18px'}}>There are two instances where we offer an optional secure push proxy that serves as a pipe between the email server and Blix app. The first is for Apple iOS devices and the second is for Android devices that specifically run the Yahoo Mail service which does not provide IMAP IDLE or an equivalent technique.</Typography>
                <Typography color="textSecondary" style={{marginTop: '30px', fontSize: '18px'}}>Apple iOS devices limit the apps background connectivity to preserve battery consumption. While it helps improving battery performance, it does not allow Blix for iOS on your device to keep a persistent direct connection between your device and your email server which is different than Android.</Typography>
                <Typography color="textSecondary" style={{marginTop: '30px', fontSize: '18px'}}>Since it is not possible to keep a direct open connection on your iOS device, Blix enhances the experience by offering a way to deliver instant push notifications as soon as a new email has been received to your server mailbox. In order to receive this instant push notification, a persistent connection is needed, which can be done through our push proxy. If you wish, you may select other sync modes (Fetch or Manual) which do not require the push proxy at any time.</Typography>
                <Typography color="textSecondary" style={{marginTop: '30px', fontSize: '18px'}}>The push proxy is a secure service that delivers notifications from your email server provider (such as Gmail or Exchange), to the platform specific push service of your device. Blix push proxy receives email headers from the server and in 1-2 seconds pushes them to your platform specific push service of your device, after which it will be automatically and completely deleted. Push emails are never stored </Typography>
                <Typography color="textSecondary" style={{marginTop: '30px', fontSize: '18px'}}>The push proxy has only one single purpose, which is to enhance the email experience and deliver instant notifications to your device when a direct connection from the app is not feasible. This means that we do not, at any time, use the information for any other purpose.</Typography>
                <Typography color="textSecondary" style={{marginTop: '30px', fontSize: '18px'}}>Moreover, Blix app on the device itself uses OAuth 2.0 where applicable and has no access to your account password. Instead, it uses a token provided by the email provider to connect to the email server.</Typography>
                <Typography color="textSecondary" style={{marginTop: '30px', fontSize: '18px'}}>If you have any question or comment, feel free to <a style={{color: 'rgba(0, 0, 0, 0.54)'}} href="mailto:privacy@blix.net">keep in touch</a> with our team to learn more about Blix’s highly secured solution.</Typography>
            </Container>
        </Layout>
          )}
        />
  )
  
  
}
export default instantPush;